@import 'animate.css';
@import 'styles/variables';
@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: 'graphik';
  src: url('assets/fonts/graphik.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'means';
    src: url('assets/fonts/means.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

html, body {
  position: relative;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: Graphik Web,Roboto,Helvetica Neue,Helvetica,Arial,Verdana,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.fontFamilyMeans {
    font-family: 'means', sans-serif!important;
}

.backgroundJasmine {
    background-color: $edu-jasmine !important;
}

.backgroundEduBrown {
    background-color: $edu-brown!important;
}

.backgroundEduOrange {
    background-color: $edu-orange!important;
}

.backgroundEduRed {
    background-color: $edu-red!important;
}

.colorEduOrange {
    color: $edu-orange!important;
}

.colorEduBrown {
    color: $edu-brown!important;
}

.colorEduRed {
    color: $edu-red!important;
}

.colorEduJasmine {
    color: $edu-jasmine!important;
}

.image-gallery {
  width: 80vw;
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.image-gallery-slide img {
  min-height: calc(100vh - 80px);
  object-fit: contain;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

ul {
  margin-top: 5px;
  padding-left: 30px;
  list-style: none!important;

  & li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

ul li::before {
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 5em;
  margin-left: -1em;
  position: absolute;
}

.scale {
  transition: all linear .1s;
}

.scale:hover {
  transform: scale(1.02);
}
